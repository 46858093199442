import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import Slider from "react-slick";
import { GatsbyImage } from 'gatsby-plugin-image';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import RichText from '../../components/RichText';
import BodyText from '../../components/BodyText';
import Spacer from '../../components/Spacer';
import Link from '../../components/Link';
import ArrowRight from '../../assets/ArrowRight';
import ArrowLeft from '../../assets/ArrowLeft';
import DynamicButton from '../../components/DynamicButton';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// TODO: ADD SLIDER BAR
// ADD Slide LINKS


import { Data } from '../../components/Slices/Slices';
import * as S from './ProductCarousel-styled';

interface ArrowProps {
  onClick?: () => void;
}

const NextArrow: FC<ArrowProps> = ({ onClick }) => (
  <S.ArrowButton
    onClick={onClick}
    css={`
      position: absolute;
      bottom: -1rem;
      right: 0.0625rem;
    `}
  >
    <ArrowRight />
  </S.ArrowButton>
);

const PrevArrow: FC<ArrowProps> = ({ onClick }) => (
  <S.ArrowButton
    onClick={onClick}
    css={`
      position: absolute;
      bottom: -1rem;
      right: 3.125rem;
    `}
  >
    <ArrowLeft />
  </S.ArrowButton>
);

export const ProductCarousel: FC<Data> = ({ data }) => {
  const { ref, inView } = useInView({ threshold: 0.15});
  const isTabletUp = useBreakpoint(up('tablet-landscape'));
  const isPhoneUp = useBreakpoint(up('tablet-portrait'));
  const { primary, items } = data;
  const { slider_link_content, slider_title, slider_link } = primary

  const checkScreenSize = () => isTabletUp ? 3 : 2;

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: isPhoneUp ? checkScreenSize() : 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };


  return (
    <>
    <Spacer spacing="md3" />
    <S.Container ref={ref} inView={inView}>
      <S.HeaderContainer>
        <RichText
          document={slider_title.richText}
          textSize={[null, null, "f8", "f9"]}
          css={`
          padding-left: .5rem;
          `}  
        />
        {!isPhoneUp && <Spacer spacing="md2" />}
        {isPhoneUp && (
          <DynamicButton url={slider_link?.url} type={slider_link?.type} color="navy">
            {slider_link_content}
          </DynamicButton>
        )}
      </S.HeaderContainer>
      <Spacer spacing="md2" />
      <S.SliderWrapper>
        <Slider {...settings}>
          {items?.map((item, index) => {
            return (
              <S.SlideContainer key={`${item?.slide_title}-${index}`}>
                <Link url={item?.slide_link?.url} type={item?.slide_link.type}>
                   <S.ContentContainer>
                    <S.ImageContainer>
                      <GatsbyImage image={item.slide_image.gatsbyImageData} alt="" />
                    </S.ImageContainer>
                    <Spacer spacing="md2" />
                    <S.MetaContainer>
                      {!!item?.slide_title && (
                        <S.RowContainer>
                          {item?.is_button && (
                            <>
                              <S.Circle />
                              <Spacer spacing="sm4" direction="vertical" /> 
                            </>
                          )}
                          <BodyText>
                            {item.slide_title}
                          </BodyText>
                        </S.RowContainer>

                      )}
                      <S.RowContainer>
                        {!!item?.product_price && (
                          <BodyText>
                            {item.product_price}
                          </BodyText>
                        )}
                        {!!item?.product_full_price && (
                          <>
                            <Spacer spacing="sm3" direction="vertical" />
                            <BodyText
                              textColor="gray"
                              css={`
                                text-decoration: line-through;
                              `}
                            >
                              {item.product_full_price}
                            </BodyText>
                          </>
                        )}
                      </S.RowContainer>
                    </S.MetaContainer>
                  </S.ContentContainer>
                </Link>
                <Spacer spacing="md1" direction="vertical" />
              </S.SlideContainer>
            )
          })}
        </Slider>
        <Spacer spacing="lg1" />
      </S.SliderWrapper>
      {!isPhoneUp && (
          <DynamicButton url={slider_link?.url} type={slider_link?.type} color="navy">
            {slider_link_content}
          </DynamicButton>
        )}
    </S.Container>
    <Spacer spacing="md3" />
    </>
  )
};


export default ProductCarousel;
