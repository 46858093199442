import React, { useEffect, useRef, useState } from 'react';
import BodyText from '../BodyText';
import Spacer from '../Spacer';
import * as S from './PopUp-styled';
import Logo from '../../assets/Logo';
import NewsLetterForm from '../NewsLetterForm';
import X from '../../assets/x';
import MiniPopUp from './components/MiniPopUp';


const imageURL = 'https://images.prismic.io/us-web-static/a09ae419-8616-4cfd-8f99-9f4e88e3c1d0_desktop_homepage_info_5.png.png?auto=format,compress';

const PopUp = () => {
  const [showMiniPopup, setShowMiniPopup] = useState(false);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const handleClose = () => {
    sessionStorage.setItem('hasSeenPopUp', 'true');
    const modalElement = modalRef.current;
    modalElement?.close();
    setShowMiniPopup(true);
  }

  const handleOpen = () => {
    const modalElement = modalRef.current;
    modalElement?.showModal();
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    const hasSeenPopUp = sessionStorage.getItem('hasSeenPopUp') === 'true';
    setShowMiniPopup(hasSeenPopUp);
    const timer = setTimeout(() => {
      if (modalElement && !hasSeenPopUp) { modalElement.showModal()}
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <S.Modal ref={modalRef} >
        <S.ButtonContainer>
          <S.Button onClick={handleClose}>
            <X aria-label="Close modal" />
          </S.Button>
        </S.ButtonContainer>
        <S.Container>
          <S.SubContainer>
            <Spacer spacing="md1" />
            <BodyText
              textColor='navy'
              fontFamily='parnaso'
              textSize="f11"
              alignment="center"
              maxWidth="200px"
              lineHeight='1'
            >
              20% OFF
            </BodyText>
            <BodyText
              textColor='navy'
              fontFamily='parnaso'
              textSize="f4"
              alignment="center"
              maxWidth="200px"
              lineHeight='1.1'
            >
              YOUR <i>first</i> PURCHASE
            </BodyText>
            <Spacer spacing="sm3" />
            <S.ImageContainer>
              <S.Img src={imageURL} alt=''/>
            </S.ImageContainer>
            <Spacer spacing="md1" />
            <NewsLetterForm 
              color='navy' 
              direction='column' 
              buttonColor='navy' 
              placeholder='EMAIL ADDRESS' 
              borderRadius='5px'
            />
            <BodyText
              textColor='navy'
              alignment="center"
              textSize="f0"
              maxWidth="370px"
              style={{textWrap: 'balance'}}
            >
              *Offer valid only for first time customers on full-sized purchases. New products are excluded.
            </BodyText>
            <BodyText
              textColor='navy'
              alignment="center"
              textSize="f0"
              maxWidth="370px"
              style={{textWrap: 'balance'}}
            >
              By subscribing you agree to our Privacy Policy & Terms of Service.**
            </BodyText>
            <Spacer spacing="lg2" />
            <S.LogoContainer>
              <S.LogoSubContainer>
                <Logo width="100%" height="100%" />
              </S.LogoSubContainer>
            </S.LogoContainer>
          </S.SubContainer>
        </S.Container>
      </S.Modal> 
      {showMiniPopup && <MiniPopUp onClick={handleOpen} />}
    </>
  );
};


export default PopUp;
