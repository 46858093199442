import React from 'react';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';
import Button from '../../components/Button';
import BodyText from '../../components/BodyText';

const Container = styled.section`
  position: relative;
  padding: 1rem;
  max-width: 1440px;
  margin: auto;

  ${up('tablet-portrait')} {
    padding: 1rem 1.5rem;
  }
`;

const VideoContainer = styled.div`
  border-bottom-left-radius: 31% 30%;
  overflow: hidden;
  position: relative;

  ${up('tablet-portrait')} {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20% 33%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  bottom: 0;
  align-items: start;

  ${up('tablet-portrait')} {
    align-items: flex-end;
    position: absolute;
    right: 1.5rem;
    bottom: 1rem;
  }

`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${up('tablet-portrait')} {
    width: fit-content;
    align-items: flex-end;
    padding: 1.5rem;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  padding: 110.5% 0 0 0;


  ${up('tablet-portrait')} {
    padding: 56.25% 0 0 0;
  }
`;

const URL_BASE = 'https://player.vimeo.com/video';
const videoConfig = 'badge=0&autopause=0&player_id=0&app_id=58479&controls=0&autoplay=1&loop=1&muted=1'

const FullScreenVideo: React.FC<{ data: any }> = ({ data: { primary }}) => {

  console.log('primary', primary)
  const isTabletUp = useBreakpoint(up('tablet-portrait'));



  const videoId = isTabletUp ? primary.video_id : primary.mobile_video_id;
  const videoLink = new URL(`${URL_BASE}/${videoId}?${videoConfig}`)

  return (
    <>
      <Spacer spacing={['md3']} />
      <Container>
        <VideoContainer>
          <VideoWrapper>
          <iframe
            src={videoLink.href}
            frameBorder="0"
            allow="autoplay"
            style={{position: 'absolute', inset: '0', width: '100%', height: '100%'}}
            title="Application 6"
          />
          <script src="https://player.vimeo.com/api/player.js"></script>
          </VideoWrapper> 
        </VideoContainer>
        <ContentContainer>
            <ContentWrapper>
              <BodyText as="p" textSize={[null, "f3", 'f3']}>
              {primary?.eyebrow}
              </BodyText>
              <Spacer spacing="sm2" />
              <RichText
                textSize={[null, "f9", "f10"]}
                document={primary?.title1?.richText}
              />
              <Spacer spacing="sm2" />
              <RichText
                textSize={[null, "f3", "f4"]}
                document={primary?.subtitle1?.richText}
              />
              <Spacer spacing="md1" />
              <Button
                color={primary.video_button_color}
                url={primary?.video_cta_link?.raw?.url}
              >
                {primary?.cta_text}
              </Button>
            </ContentWrapper>
          </ContentContainer>
      </Container>
      <Spacer spacing={['md3']} />
    </>
  )
}

export default FullScreenVideo;