import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Container as SectionContainer } from '../../styles/SectionContainer';

export const Container = styled.section<{ inView: boolean; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  padding: 1rem;
  ${SectionContainer};

  .slick-initialized {
    padding: 5rem 0 1rem;
    overflow-x: hidden;

    ${up('tablet-portrait')} {
      padding: 5rem 0;
    }
  }

  .publisher-logo {
    width: 100%;
    height: 100%;
    max-width: 250px;
    margin: 0 auto;

    ${up('tablet-portrait')} {
      max-width: 471px;
    }


    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 25px;
      left: 0px;
    }

    ul li {
      width: 100%;
      padding-right: 20px;

      ${up('tablet-portrait')} {
        max-width: 100px;
      }
    }

    img {
      opacity: 0.3 !important;
    }

    .slick-active img {
      opacity: 1 !important;
    }

    li:hover {
      cursor: pointer;
    }
  }
  .logo-img {
    width: 100%;
    height: 20px;
    max-width: 100px;
  }
`;

export const ReviewContainer = styled.div`
`;
