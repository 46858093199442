import React, { useEffect, useCallback, useState } from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy";
import { useStaticQuery, graphql } from 'gatsby';

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_MYSHOPIFY_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const shippingInsuranceRates = graphql`
  query {
    shopifyProduct(title: {eq: "Shipping Protection by Route"}) {
      featuredImage {
        gatsbyImageData
      }
      variants {
        storefrontId
        price
      }
    }
  }
`;

export const getSum = (lineItems, withInsurance = false) => {
  if (lineItems?.length === 0) return 0;
  return lineItems.reduce((accumulator, next) => {
    if (!withInsurance) {
      if(next?.customAttributes?.[0]?.value === 'Insurance') {
        return accumulator;
      }
    }
    const nextQuantity = next?.quantity;
    const nextPrice = next?.variant?.price?.amount ?? 0;
    return (accumulator + (nextQuantity * nextPrice));
  }, 0);
};

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  handleCheckout: () => {},
  client,
  routeProtectionData: {},
  handleWithInsurance: () => {},
  withInsurance: true,
  fullPageLoading: false,
  checkout: {
    lineItems: [],
  },
  subTotal: 0,
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false);
  const [fullPageLoading, setFullPageLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insurancePrice, setInsurancePrice] = useState(0);
  const [withInsurance, setWithInsurance] = useState(true);
  const { shopifyProduct: { variants: insuranceData, featuredImage } } = useStaticQuery(shippingInsuranceRates);

  const handleWithInsurance = () => {
    setWithInsurance(!withInsurance);
  }

  const setCheckoutItem = (checkout) => {
    if (isBrowser) localStorage.setItem(localStorageKey, checkout.id);
    // We hide the Insurance Line Item from the cart
    // so we need to skip it when calculating the subtotal
    // once we checkout the insurance gets reset to the correct amount
    const subTotalSum = getSum(checkout.lineItems);
    setSubTotal(subTotalSum);
    setCheckout(checkout);
    getQuote(subTotalSum);
  };

  const getQuote = useCallback(async (subTotalAmount) => {
    if (subTotalAmount === 0) {
      setInsurancePrice(0);
      setInsuranceId(null);
      return;
    }
    const { get_quote } = window.routeapp || {};
    const insuranceCallback = async (quote) => {
      const { insurance_price: insurancePrice } = quote;
      let index = 0;
      for (let i = 0; i < insuranceData?.length; i++) {
        if (insuranceData[i].price >= insurancePrice) {
          index = i;
          break;
        }
      };
      setInsuranceId(insuranceData?.[index]?.storefrontId);
      setInsurancePrice(insuranceData?.[index]?.price);
      return insuranceData?.[index]?.price;
    };

    try {
      const result = await get_quote(process.env.GATSBY_ROUTE_PUBLIC_KEY, subTotalAmount, 'USD', insuranceCallback);
      return result;
    } catch (e) {
      console.error('get quote error', e);
    }
  }, [insuranceData]);

  const addVariantToCart = useCallback(async (variantId, quantity, productType, price) => {


    setLoading(true);
    let subTotalSum = quantity * price;
    const checkoutID = checkout.id;
    const checkoutLineItems = checkout?.lineItems ?? [];
    const newCheckoutItems = [];
    let isNewItem = true;

    if (checkoutLineItems.length > 0) {
      checkoutLineItems.forEach((item) => {
        if (item?.variant?.id === variantId) isNewItem = false;
        if (item?.customAttributes?.[0]?.value === 'Insurance') return;
        if (item?.variant?.id === variantId && item.quantity === 1 && quantity === -1) return;
        newCheckoutItems.push({
          variantId: item?.variant?.id,
          quantity: item?.variant?.id === variantId ? (parseInt(quantity, 10) + item.quantity) : item.quantity,
          customAttributes:[{ key: "Product", value: item?.customAttributes?.[0]?.value }],
        });
      });
      subTotalSum = subTotalSum + getSum(checkoutLineItems);
    };

    if (isNewItem) {
      newCheckoutItems.push({
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes: [{ key: "Product", value: productType }]
      });
    };
  
    const replaceLineItems = async () => {
      try {
        const result = await client.checkout.replaceLineItems(checkoutID, newCheckoutItems)
        return result;
      } catch (error) {
        console.error('failed to replace items', error);
      }
    };

    try {
      if (loading) return;
      const results = await Promise.all([replaceLineItems(), getQuote(subTotalSum)]);
      setCheckout(results[0]);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  }, [checkout, loading, getQuote]); 

  const handleCheckout = useCallback(async () => {
    setFullPageLoading(true);
    const checkoutItems = [];
    const checkoutLineItems = checkout?.lineItems ?? [];
    if (checkoutLineItems.length > 0) {
      checkoutLineItems.forEach((item) => {
        if (item?.customAttributes?.[0]?.value === 'Insurance') return;
        checkoutItems.push({
          variantId: item?.variant?.id,
          quantity: item.quantity,
          customAttributes:[{ key: "Product", value: item?.customAttributes?.[0]?.value }],
        });
      });
      withInsurance && checkoutItems.push({
        variantId: insuranceId,
        quantity: 1,
        customAttributes:[{ key: "Product", value: 'Insurance' }],
      });
    };

    const result = await client.checkout.replaceLineItems(checkout.id, checkoutItems);
    window.open(result.webUrl, '_self');
    setFullPageLoading(false);
  }, [checkout, insuranceId, withInsurance]);

  // initialize checkout
  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null
      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          console.error(`No Checkout found: ${e}`)
          localStorage.setItem(localStorageKey, null)
        }
      }
      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout)
    }
    initializeCheckout()
  }, []);

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        checkout,
        loading,
        insurancePrice,
        handleWithInsurance,
        withInsurance,
        fullPageLoading,
        routeProtectionData: {
          insuranceData,
          featuredImage,
        },
        subTotal,
        handleCheckout,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
