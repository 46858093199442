import { createGlobalStyle, css } from 'styled-components';

// Optionally provides the reset outside of `createGlobalStyle`
// 1. A base font size value of 16px.
// 2. A base line height ratio.
// 3. Vertical rhythm calculation: 18px * 1.5 = 27px
export const BaseCSSReset = css`
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: normal;
  }

  blockquote,
  dl,
  dd,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  hr,
  h6,
  p,
  input,
  pre {
    margin: 0;
  }
  ul {
    padding-left: 0;
    list-style: none;
  }
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  sup {
    top: 0;
  }
  img {
    max-width: 100%;
    border-style: none;
  }
  html {
    font-family: 'SourceSansPro-Regular';
    font-size: 1rem; /* [1] */
    line-height: 1.25; /* [2] */
    -webkit-font-smoothing: antialiased;
    scrollbar-gutter: stable;
    scroll-behavior: smooth;
  }
  body {
    min-height: 100vh;
    background-color: #F5F3EE;
    color: #000;
  }
  main {
    min-height: 75vh;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.5;

    margin-bottom: 1.6875rem; /* [3] */

    &:last-child {
      margin-bottom: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
    margin-bottom: 0.35em;
  }
  fieldset {
    border: none;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
  button {
    font-family: 'SourceSansPro-Regular';
    font-size: 1rem;

    padding: 0;
    border-style: none;
    border-width: 0;

    background-color: transparent;

    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  select::-ms-expand {
    display: none;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${BaseCSSReset};

  @font-face {
    font-family: 'Parnaso';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Parnaso-Regular.woff2) format('woff2'),
    url(/fonts/Parnaso-Regular.woff) format('woff'),
    url(/fonts/Parnaso-Regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Parnaso';
    font-style: italic;
    font-weight: 400;
    src: url(/fonts/Parnaso-RegularItalic.woff2) format('woff2'),
    url(/fonts/Parnaso-RegularItalic.woff) format('woff'),
    url(/fonts/Parnaso-RegularItalic.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Helvetica.ttc) format('truetype');
  }

  @font-face {
    font-family: 'Canonatia';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Canonatia.otf) format('opentype'),
    url(/fonts/Parnaso-RegularItalic.woff) format('woff'),
    url(/fonts/Parnaso-RegularItalic.woff2) format('woff2');
  }
`;

export default GlobalStyle;
