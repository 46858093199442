import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Link from '../../components/Link';
import BodyText from '../../components/BodyText';
import Spacer from '../../components/Spacer';
import RichText from '../../components/RichText';
import Heading from '../../components/Heading';
import Scroller from '../../components/Scroller';

import * as S from './RecommendedProducts-styled';

interface RecommendedProductsProps {
  data: any;
};

const RecommendedProducts: FC<RecommendedProductsProps> = ({ data: { items } }) => {
  return (
    <>
      <Spacer spacing="md1" />
      <S.Container >
        <Heading textSize={[null, "f8","f10"]}>
          COMPLETE <i>your</i> ROUTINE
        </Heading>
        <Spacer spacing="md3" />
        <Scroller>
          <S.SubContainer>
            {items.map((product: any, index: number) => {
              return (
                <Link url={`/product/${product?.url}`} key={`${product?.url}-${index}`}>
                  <S.CardContainer key={product?.url}>
                    {product?.['discount_percentage'] && (
                      <S.DiscountSticker>
                        <BodyText fontFamily="parnaso" textColor="white">
                          SAVE
                        </BodyText>
                        <BodyText textColor="white">
                          {`${product?.['discount_percentage']}%`}
                        </BodyText>
                      </S.DiscountSticker>
                    )}
                    <GatsbyImage image={product.product_image.gatsbyImageData} alt="" />
                    <Spacer spacing="md2"/>
                    <S.TitleContainer>
                      <RichText
                        document={product?.product_title?.richText}
                        fontFamily="helvetica"
                        textTransform="uppercase"
                        textSize="f3"
                      />
                      <Spacer spacing="sm3" direction="vertical" />
                      <S.PriceContainer>
                        <BodyText>
                          {`$${product?.price}`}
                        </BodyText>
                        {product?.original_price > 0 && (
                          <>
                            <Spacer spacing="sm3" direction="vertical" />
                              <BodyText css="text-decoration: line-through" textColor="midGray">
                              {`$${product?.original_price}`}
                            </BodyText>
                          </>
                        )}
                      </S.PriceContainer>
                    </S.TitleContainer>
                    <Spacer spacing="md2"/>
                    <BodyText>
                      {product?.product_description}
                    </BodyText>
                  </S.CardContainer>
                </Link>
              )
            })}
          </S.SubContainer>
        </Scroller>
      </S.Container>
    </>
  );
};

export default RecommendedProducts;
